
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';


// import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import Login from './Login';
import Logout from './Logout';
import Signup from './Signup';
import Home from './Home';
// import Header from './Header';
import { Divider } from '@mui/material';
import AdminUserPage from './pages/AdminUserPage';

function App() {
  const isLoggedIn = sessionStorage.getItem('logged_in') === 'true';
  const isAdmin = sessionStorage.getItem('is_admin') === 'true';
  console.log("-------- reload APP -----------")
  console.log(isLoggedIn)

  return (
    // <div className="App">
    //   <div>
    //     <h1>헤더임</h1>
    //   </div>
    //   <Router>
    //     <Routes>
    //       <Route path="/login" element={<Login />} />
    //       {/* <Route path="/"
    //         element={isLoggedIn ? <Home /> : <Navigate to="/login" />}
    //         /> */}
    //       <Route path="/logout" element={<Logout />} />
    //       <Route path="/signup" element={<Signup />} />
    //       <Route path="/"
    //         element={isLoggedIn ? <Home /> : <Navigate to="/login" />}
    //       />
    //     </Routes>
    //   </Router>
    // </div>

    <div>
      <Stack>

        {/* <Stack direction="column" alignItems="stretch"> */}
        {/* <Divider /> */}
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/home"
              element={isLoggedIn ? <Home /> : <Navigate to="/" />}
            />
            <Route path="/administrator"
              element={isAdmin ? <AdminUserPage /> : <Home />}
            />
          </Routes>
        </Router>
        {/* </Stack> */}
      </Stack>
    </div>
  );
}

export default App;
