// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import './Signup.css'
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';


function Signup() {
	const [userid, setUserid] = useState('');
	const [password, setPassword] = useState('');
	const [password_confirm, setPasswordConfirm] = useState('');
	const [phone, setPhone] = useState('');
	const [name, setName] = useState('');
	const [message, setMessage] = useState('');

	const handleSignUp = async () => {
		// check id
		
		// check password same

		// check name
		
		// check phone number (remove -)

		try {
			const response = await fetch('/api/signup', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ userid, password, name, phone }),
			});

			const data = await response.json();
			setMessage(data.message);
		} catch (error) {
			console.error('Error signing up:', error);
			setMessage('An error occurred while signing up.');
		}
	};

	const navigate = useNavigate();
	const handleToLogin  = async () => {
		navigate('/');
	};

	return (
		// <div className='signup_box'>
		// 	<h1>회원가입</h1>
		// 	<input
		// 		type="text"
		// 		placeholder='userid'
		// 		value={userid}
		// 		onChange={(e) => setUserid(e.target.value)}
		// 	/>
		// 	<input
		// 		type="password"
		// 		placeholder='Password'
		// 		value={password}
		// 		onChange={(e) => setPassword(e.target.value)}
		// 	/>
		// 	<input
		// 		type="password"
		// 		placeholder='Password'
		// 		value={password_confirm}
		// 		onChange={(e) => setPasswordConfirm(e.target.value)}
		// 	/>
		// 	<input
		// 		type="text"
		// 		placeholder='전화번호'
		// 		value={phone}
		// 		onChange={(e) => setPhone(e.target.value)}
		// 	/>
		// 	<input
		// 		type="text"
		// 		placeholder='이름'
		// 		value={name}
		// 		onChange={(e) => setName(e.target.value)}
		// 	/>
		// 	<button onClick={handleSignUp}>Sign Up</button>
		// 	<p>{message}</p>
		// 	<p>Already have an account? <Link to="/">Login</Link></p>
		// </div>
		<div>
			<Container component="main" maxWidth='xs'>
				<Box
				  sx={{
					marginTop: 8,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				  }}
				>
				  <Typography component="h1" variant="h5" mb={3}>
					회원 가입 
				  </Typography>
				  <Paper elevation={20} >
						<Box sx={{ m: 5 }}>
							<TextField
								label="user id" margin="normal"
								required fullWidth autoFocus
								id="userid" name="userid"
								onChange={(e) => setUserid(e.target.value)} />
							<TextField
								label="Password" type="password" margin="normal"
								required fullWidth name="password"
								id="password" autoComplete="current-password"
								onChange={(e) => setPassword(e.target.value)} />
							<TextField
								label="Password Confirm" type="password" margin="normal"
								required fullWidth name="password"
								id="password" autoComplete="current-password"
								onChange={(e) => setPasswordConfirm(e.target.value)} />
							<TextField
								label="이름" margin="normal"
								required fullWidth
								id="name" name="name"
								onChange={(e) => setName(e.target.value)} />
							<TextField
								label="휴대폰 번호" margin="normal"
								required fullWidth
								id="phone" name="phone"
								onChange={(e) => setPhone(e.target.value)}/>
					  <p>{message}</p>
					</Box>
				  </Paper>
					<Stack
						direction="row"
						justifyContent="center"
						alignItems="center"
						spacing={0}>
						<Button
							variant='contained' fullWidth type="submit"
							onClick={handleSignUp}
							sx={{ mt: 3, mb: 2, width: 150 }}
						>
							회원가입
						</Button>
						<Button
							fullWidth type="submit"
							onClick={handleToLogin}
							sx={{ mt: 3, mb: 2 }}
						>
							로그인
						</Button>
					</Stack>

				</Box>
			  </Container>
		</div>
	);
}


export default Signup;