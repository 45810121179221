import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';


function MbtiEegCompareTableDesc(props) {
	return (
		<div>
			<Stack
				direction="row"
				justifyContent="flex-start"
				alignItems="center"
				spacing={0} sx={{ m: 0 }}>

				<Typography fontSize={10} color="black" sx={{ m: 0 }}>
					MBTI 결과는&nbsp;
				</Typography>
				<Typography fontSize={10} color="blue" sx={{ m: 0 }}>
					{props.mbti_type}
				</Typography>
				<Typography fontSize={10} color="black" sx={{ m: 0 }}>
					으로 나왔지만, 현재 뇌기능은&nbsp;
				</Typography>
				<Typography fontSize={10} color="red" sx={{ m: 0 }}>
					{props.eeg_type}
				</Typography>
				<Typography fontSize={10} color="black" sx={{ m: 0 }}>
					처럼 발현되고 있음
					{/* 처럼 발현되고 있음 => {props.result} */}
				</Typography>
			</Stack>
		</div>
	);
}

export default MbtiEegCompareTableDesc;