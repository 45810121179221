import * as React from 'react';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


const user_table_columns = [
    { id: 'userid', label: 'id', minWidth: 40, maxWidth: 60 },
    { id: 'name', label: '이름', minWidth: 40, maxWidth: 60 },
    { id: 'phone', label: '전화번호', minWidth: 40, maxWidth: 60 },
    { id: 'create_date', label: '가입일', minWidth: 100, maxWidth: 100 },
    { id: 'buy_coupon_cnt', label: '구매한 쿠폰', minWidth: 40, maxWidth: 60 },
    { id: 'used_coupon_cnt', label: '사용한 쿠폰', minWidth: 40, maxWidth: 60 },
    { id: 'cur_coupon_cnt', label: '보유 쿠폰', minWidth: 40, maxWidth: 60 },
];

function AdminUserPage() {
    // question table ==========================================================================
    const [users, setUsers] = React.useState(100);
    const userid = sessionStorage.getItem('userid');

    React.useEffect(() => {
        fetch('/api/admin/user_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid })
        })
            .then((response) => {
                // 응답 데이터를 JSON으로 파싱
                return response.json();
            })
            .then((jsonData) => {
                // 성공적으로 데이터를 가져온 경우 상태 업데이트
                setUsers(jsonData);
                console.log(jsonData)
                console.log(users.length)
            })
    }, []);// 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행

    // const handleClick = (n) => {
    //     console.log('----' + n + '------')
    // };

    // coupon state ==========================================================================
    // const [buy_coupon, setBuyCoupon] = React.useState(0);
    const [user_mgr_dialog_open, setUserMgrDialogOpen] = React.useState(false);
    const [req_buycoupon_num, setReqBuyCouponNum] = React.useState(false);
    const [req_buycoupon_price, setReqBuyCouponPrice] = React.useState(false);
    const [cur_user, setCurUser] = React.useState(null);

    const handleBuyCouponOpen = async (n) => {
        // setQuestionMessage("");
        setUserMgrDialogOpen(true);
        setCurUser(n);
    }

    const handleUserMgrDialogClose = () => {

        setUserMgrDialogOpen(false);
    }

    const handleDeleteUser = () => {
         // var ret = confirm("정말 user를 삭제 하시겠습니까?. 삭제된 유저 관련 정보는 복구되지 않습니다.")
        const isConfirmed = window.confirm('정말 user를 삭제 하시겠습니까?. 삭제된 유저 관련 정보는 복구되지 않습니다.');

        if (isConfirmed) {
        
            fetch('/api/admin/deluser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ cur_user })
            })
                .then((response) => {
                    // 응답 데이터를 JSON으로 파싱
                    return response.json();
                })
                .then((jsonData) => {
                    // 성공적으로 데이터를 가져온 경우 상태 업데이트
                    console.log(jsonData)
                    window.alert("유저가 삭제 되었습니다.");
                    handleUserMgrDialogClose();
                    window.location.reload();
                })
        }
    }

    const handleBuyCoupon = async () => {
        if (req_buycoupon_num == 0 ){
            alert('쿠폰의 갯수를 입력하세요');
        } else {
            console.log("BuyCoupon");
            fetch('/api/admin/buy_coupon', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ cur_user, req_buycoupon_num })
            })
            .then((response) => {
                // 응답 데이터를 JSON으로 파싱
                return response.json();
            })
            .then((jsonData) => {
                console.log(jsonData)
                // 성공적으로 데이터를 가져온 경우 상태 업데이트
                // setUserData(jsonData);
                // setName(jsonData.name);
                // setPhone(phoneNumber(jsonData.phone));
                // setCurCoupon(jsonData.cur_coupon_cnt);
                // setUsedCoupon(jsonData.used_coupon_cnt);
                // setBuyCoupon(jsonData.buy_coupon_cnt);

                window.location.reload();
            })
        }
    };



    const user_mgr_dialog = (
        <div>
            <Dialog
                open={user_mgr_dialog_open}
                onClose={handleUserMgrDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"유저 관리 : "} {cur_user}
                </DialogTitle>
               
                <DialogContent>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        mt={2}>
                        <Typography ml={2} mr={2}>
                            쿠폰 관리 : <br />
                            &nbsp;&nbsp;쿠폰의 갯수를 선택하고, '쿠폰 추가' 버튼을 누르세요<br />
                            &nbsp;&nbsp;'-'를 입력하면 쿠폰의 갯수가 줄어듭니다.
                        </Typography>

                        <Stack
                            direction="row"
                            spacing={1}>
                            <TextField
                                id='buyCouponCnt'
                                type='Number'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setReqBuyCouponNum(e.target.value)}
                                ></TextField>
                            <Button variant='contained' onClick={handleBuyCoupon} size='small'>
                                쿠폰 추가
                            </Button>
                        </Stack>
                        <Typography ml={2} mr={2}>
                            유저 삭제 : <br />
                            &nbsp;&nbsp;유저를 삭제 합니다.(삭제된 유저는 복구되지 않습니다.)
                        </Typography>
                        <Button variant='contained' onClick={handleDeleteUser} size='small' autoFocus>
                            유저삭제
                        </Button>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    
                    <Button variant='contained' onClick={handleUserMgrDialogClose} size='small' autoFocus>
                        취소
                    </Button>
                   
                </DialogActions>
            </Dialog>
        </div>
    )

    return (
        <div>
            <Paper elevation={2}>
                {/* <TableContainer sx={{ maxHeight: 440 , border: 1, borderColor: 'grey.300', borderRadius: 3}}> */}
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {user_table_columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        sx={{ backgroundColor: 'grey.300' }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Array.isArray(users) ?
                                    users.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                {user_table_columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell onClick={() => handleBuyCouponOpen(row.userid)}
                                                            key={column.id} align={column.align}
                                                            style={{ maxWidth: 100 }}>
                                                            {column.format && typeof value === 'number'
                                                                ? column.format(value)
                                                                : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    }) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {user_mgr_dialog}
        </div>
    );
}


export default AdminUserPage;