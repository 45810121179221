// import * as React from 'react';
import React, { useState } from 'react';
import MbtiEegResultChart from './MbtiEegResultChart';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { TextField } from '@mui/material';


import brainfactoryLogo from '../images/brainfactory_logo01_mini.png'
import brainfactoryBottomLogo from '../images/brainfactory_bottom_logo.png'
import EEGStressLogo from '../images/EEG_Stress_logo.png'


import MbtiEegCompareTableDesc from './MbtiEegCompareTableDesc';

let theme = createTheme();
theme = responsiveFontSizes(theme);

theme.typography.h3 = {
	// fontSize: '1.2rem',
	// '@media (min-width:600px)': {
	//   fontSize: '1.5rem',

	//   [theme.breakpoints.up('md')]: {
	// 	  fontSize: '2rem',
	// 	},
	// },
	fontSize: '2rem',
	color: "#30518d"
};

theme.typography.h4 = {
	fontSize: '1.6rem',
	color: "#30518d"
};


theme.typography.h6 = {
	fontSize: '1.0rem',
	color: "#30518d"
};

theme.typography.h8 = {
	fontSize: '0.7rem',
	color: "grey"
};

theme.typography.mbtieeg_table_mbti_type = {
	fontSize: '0.7rem',
	color: 'blue'
};

theme.typography.mbtieeg_table_mbti_type_2 = {
	fontSize: '0.6rem',
	color: 'red'
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		// backgroundColor: theme.palette.common.black,
		// color: theme.palette.common.white,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		fontSize: 13,
		m: 0,
		height: 1,
		padding: '10px 0px'
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 13,
		m: 0,
		height: 1,
		padding: '10px 0px'
	},
}));



function createData(mbti_result, eeg_spectrum, currect, desc) {
	return { mbti_result, eeg_spectrum, currect, desc };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const mbti_analysis_ret_detail_list = [
	// createData('E지표선호', 'I경향분포', ' ', 
	// 	<MbtiEegCompareTableDesc mbti_type='외향형' eeg_type='내향형' result='쉽게 지침' />),
	// createData('I지표선호', 'E경향분포', ' ',
	// 	<MbtiEegCompareTableDesc mbti_type='내향형' eeg_type='외향형' result='답답함' />),
	createData('S지표선호', 'N경향분포', ' ',
		<MbtiEegCompareTableDesc mbti_type='감각형' eeg_type='직관형' result='예민함' />),
	createData('N지표선호', 'S경향분포', ' ',
		<MbtiEegCompareTableDesc mbti_type='직관형' eeg_type='감각형' result='무기력(방관)' />),
	createData('T지표선호', 'F경향분포', ' ',
		<MbtiEegCompareTableDesc mbti_type='사고형' eeg_type='감정형' result='감정을 억제함' />),
	createData('F지표선호', 'T경향분포', ' ',
		<MbtiEegCompareTableDesc mbti_type='감정형' eeg_type='사고형' result='사회적 가면' />),
	createData('J지표선호', 'P경향분포', ' ',
		<MbtiEegCompareTableDesc mbti_type='판단형' eeg_type='인식형' result='가혹한 인내임' />),
	createData('P지표선호', 'J경향분포', ' ',
		<MbtiEegCompareTableDesc mbti_type='인식형' eeg_type='판단형' result='무책임' />),
];


const graph_data = [
	{ name: 'E 외향', MBTI결과: 4, info: 'I 내향', 뇌파분포: 1 },
	{ name: 'S 감각', MBTI결과: -6, info: 'N 직관', 뇌파분포: 15 },
	{ name: 'T 사고', MBTI결과: -22, info: 'F 감정', 뇌파분포: 15 },
	{ name: 'J 판단', MBTI결과: 22, info: 'P 인식', 뇌파분포: 15 },
];


function MbtiEegResultPage(props) {
	console.log('-MbtiEegResultPage-' + props.id + '------')
	const eeg_src_id = props.mbti_eeg_src_id
	const userid = props.user_id

	const [username, setUserName] = React.useState(null);
	// const [mbti_eeg_ret, setMbtiEegRet] = React.useState(null);
	const [exam_client_name, setExamClientName] = React.useState(props.exam_client_name);
	const [exam_date_str, setExamDateStr] = React.useState(null);
	const [mbti_src_ei_score, setMbtiSrcEIscore] = React.useState(null);
	const [mbti_src_sn_score, setMbtiSrcSNscore] = React.useState(null);
	const [mbti_src_tf_score, setMbtiSrcTFscore] = React.useState(null);
	const [mbti_src_jp_score, setMbtiSrcJPscore] = React.useState(null);

	const [mbti_ret_sn_score, setMbtiRetSNscore] = React.useState(null);
	const [mbti_ret_tf_score, setMbtiRetTFscore] = React.useState(null);
	const [mbti_ret_jp_score, setMbtiRetJPscore] = React.useState(null);

	const [mbti_analysis_ret_detect_sn, setMbtiAnalysisRetDetectSN] = React.useState(" ");
	const [mbti_analysis_ret_detect_ns, setMbtiAnalysisRetDetectNS] = React.useState(" ");
	const [mbti_analysis_ret_detect_tf, setMbtiAnalysisRetDetectTF] = React.useState(" ");
	const [mbti_analysis_ret_detect_ft, setMbtiAnalysisRetDetectFT] = React.useState(" ");
	const [mbti_analysis_ret_detect_jp, setMbtiAnalysisRetDetectJP] = React.useState(" ");
	const [mbti_analysis_ret_detect_pj, setMbtiAnalysisRetDetectPJ] = React.useState(" ");

	React.useEffect(() => {
		fetch('/api/eeg_ret', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ eeg_src_id })
		})
			.then((response) => {
				// 응답 데이터를 JSON으로 파싱
				return response.json();
			})
			.then((jsonData) => {
				// 성공적으로 데이터를 가져온 경우 상태 업데이트
				console.log("------" + jsonData.exam_client_name)
				setExamClientName(jsonData.exam_client_name);
				setExamDateStr(jsonData.exam_date_str);
				setMbtiSrcEIscore(jsonData.mbti_ei_score);
				setMbtiSrcSNscore(jsonData.mbti_sn_score);
				setMbtiSrcTFscore(jsonData.mbti_tf_score);
				setMbtiSrcJPscore(jsonData.mbti_jp_score);

				setMbtiRetSNscore(jsonData.mbti_eeg_sn_score);
				setMbtiRetTFscore(jsonData.mbti_eeg_tf_score);
				setMbtiRetJPscore(jsonData.mbti_eeg_jp_score);

				setMbtiAnalysisRetDetectSN(jsonData.analysis_data_selected_SN);
				setMbtiAnalysisRetDetectNS(jsonData.analysis_data_selected_NS);
				setMbtiAnalysisRetDetectTF(jsonData.analysis_data_selected_TF);
				setMbtiAnalysisRetDetectFT(jsonData.analysis_data_selected_FT);
				setMbtiAnalysisRetDetectJP(jsonData.analysis_data_selected_JP);
				setMbtiAnalysisRetDetectPJ(jsonData.analysis_data_selected_PJ);

				console.log(jsonData)
				// console.log(questions.length)
			})

		fetch('/api/userinfo', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ userid })
		})
			.then((response) => {
				// 응답 데이터를 JSON으로 파싱
				return response.json();
			})
			.then((jsonData) => {
				// 성공적으로 데이터를 가져온 경우 상태 업데이트
				// setUserData(jsonData);
				setUserName(jsonData.name);
			})
	}, []);// 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행



	// mbti_analysis_ret_detail_list[0].currect = '*'
	// // mbti_analysis_ret_detail_list[1].currect = '*'
	// mbti_analysis_ret_detail_list[2].currect = '*'
	graph_data[0].MBTI결과 = mbti_src_ei_score;
	graph_data[1].MBTI결과 = mbti_src_sn_score;
	graph_data[2].MBTI결과 = mbti_src_tf_score;
	graph_data[3].MBTI결과 = mbti_src_jp_score;

	graph_data[1].뇌파분포 = mbti_ret_sn_score;
	graph_data[2].뇌파분포 = mbti_ret_tf_score;
	graph_data[3].뇌파분포 = mbti_ret_jp_score;

	mbti_analysis_ret_detail_list[0].currect = mbti_analysis_ret_detect_sn;
	mbti_analysis_ret_detail_list[1].currect = mbti_analysis_ret_detect_ns;
	mbti_analysis_ret_detail_list[2].currect = mbti_analysis_ret_detect_tf;
	mbti_analysis_ret_detail_list[3].currect = mbti_analysis_ret_detect_ft;
	mbti_analysis_ret_detail_list[4].currect = mbti_analysis_ret_detect_jp;
	mbti_analysis_ret_detail_list[5].currect = mbti_analysis_ret_detect_pj;

	return (
		// 결과지 디자인
		<div>
			<Paper sx={{ width: 800 }}>
				<Stack direction="column" justifyContent="flex-start"
					alignItems="center"
					sx={{ height: 1120, width: 800 }}>
					{/* 헤더 */}
					<Box sx={{
						height: 100, width: 800, boxShadow: 3,
						backgroundColor: '#bdd7ee'
					}}>
						<Stack
							direction="row" justifyContent="space-around"
							alignItems="center" spacing={10}
							sx={{ height: 100 }}
						>
							<img className="photo" src={EEGStressLogo}
								alt="brain factory Inc. logo" width={60} height={60}
							/>
							<ThemeProvider theme={theme}>
								<Typography variant="h3"
								>MBTI결과-뇌파분포 비교</Typography>
							</ThemeProvider>
							<Box sx={{ width: '60px' }}>

							</Box>
						</Stack>
					</Box>

					{/* 검사 대상자 정보  */}
					<Stack direction="column" justifyContent="center"
						alignItems="stretch"
						sx={{ height: '120px', width: '600px', mt: 3 }} >
						<Divider />
						{/*  검사 대상자 정보 첫번째 줄 */}
						<Stack direction="row" justifyContent="space-between"
							alignItems="stretch" spacing={2}
							sx={{ mt: 1, mb: 1 }}>
							<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
								<ThemeProvider theme={theme}>
									<Typography variant="h6">
										•&nbsp;측정일시 :&nbsp;&nbsp;
									</Typography>
								</ThemeProvider>
								{/* <a> 2023년 11월 23일</a> */}
								<a>{exam_date_str}</a>
							</Stack>
							<Stack direction="row" justifyContent="flex-start" alignItems="center" >
								<ThemeProvider theme={theme}>
									<Typography variant="h6">
										•&nbsp;담당 전문가 :&nbsp;&nbsp;
									</Typography>
								</ThemeProvider>
								{/* <a> 설지용 </a> */}
								<a> {username} </a>
							</Stack>
						</Stack>

						<Divider />
						{/*  검사 대상자 정보 두번째 줄 */}
						<Stack direction="row" justifyContent="space-between"
							alignItems="stretch" spacing={2} sx={{ mt: 1, mb: 1 }}>
							<Stack direction="row" justifyContent="flex-start"
								alignItems="center" spacing={1}>
								<ThemeProvider theme={theme}>
									<Typography variant="h6">
										•&nbsp;이름 :&nbsp;&nbsp;
									</Typography>
									{/* <a> {exam_client_name}</a> */}
									<div>
										<Stack

											direction="row-reverse"
											justifyContent="flex-end"
											alignItems="flex-start"

											sx={{
												width: 180,
												height: 30,
											}}
										>
											<TextField
												variant="standard"
												// placeholder="      소속 그룹"
												InputProps={{
													disableUnderline: true,
												}}
												defaultValue={exam_client_name}
												// defaultValue="설지용"
											>
											</TextField>
										</Stack>
									</div>
									{/* <Stack

										direction="row-reverse"
										justifyContent="flex-start"
										alignItems="flex-start"

										sx={{
											width: 140,
											height: 30,
										}}
									>
										<TextField
											variant="standard"
											// placeholder="(XXXX년XX월XX일)"
											InputProps={{
												disableUnderline: true,
											}}
										>
										</TextField>
									</Stack> */}
								</ThemeProvider>
							</Stack>
							<Stack direction="row" justifyContent="flex-start"
								alignItems="center" spacing={1}>
								<ThemeProvider theme={theme}>
									<Typography variant="h6">
										•&nbsp;성별 :&nbsp;&nbsp;
									</Typography>

								</ThemeProvider>
								{/* <a> 남 </a> */}
								<Stack
									direction="row-reverse"
									justifyContent="flex-start"
									alignItems="flex-start"
									sx={{
										width: 60,
										height: 30,
									}}
								>
									<TextField
										variant="standard"
										// placeholder="남 or 여"
										InputProps={{
											disableUnderline: true,
										}}
									>
									</TextField>
								</Stack>
							</Stack>
							<Stack direction="row" justifyContent="flex-start"
								alignItems="center" spacing={1}>

								<ThemeProvider theme={theme}>
									<Typography variant="h6">
										•&nbsp;소속 :&nbsp;&nbsp;
									</Typography>
									{/* <a> 대유플러스 </a> */}
									<div>
										<Stack

											direction="row-reverse"
											justifyContent="flex-end"
											alignItems="flex-start"

											sx={{
												width: 115,
												height: 30,
											}}
										>
											<TextField
												variant="standard"
												placeholder="      소속 그룹"
												InputProps={{
													disableUnderline: true,
												}}
											>
											</TextField>
										</Stack>
									</div>
								</ThemeProvider>
							</Stack>
						</Stack>
						<Divider />
					</Stack>

					{/* MBTI-결과표 소개 */}
					<Box sx={{ width: '700px', mt: 2 }}>
						<ThemeProvider theme={theme}>
							<Typography variant="h4">
								MBTI결과-뇌파분포 그래프
							</Typography>
						</ThemeProvider>
						<a>MBTI 선호지표 결과와 뇌파측정 결과의 양상을 비교합니다.</a>
					</Box>
					{/* MBTI-결과표 */}
					<Box sx={{
						border: 1, borderRadius: '16px',
						borderColor: '#bdd7ee', mt: 1
					}}>
						<MbtiEegResultChart data={graph_data} />
					</Box>


					{/* MBTI-결과표 비교 */}
					<Box sx={{ width: '700px', mt: 4 }}>
						<ThemeProvider theme={theme}>
							<Typography variant="h4">
								MBTI결과-뇌파분포 차이 해석
							</Typography>
						</ThemeProvider>
						<a>MBTI지표들 중 특정지표를 선호하는 집단에서 나타나는 뇌파분포와 상반된 결과를 통한 해석입니다. </a>
					</Box>
					{/* MBTI-결과표 */}
					<Box sx={{
						border: 1, borderRadius: '16px',
						borderColor: '#bdd7ee'
					}}>

					</Box>


					<Box sx={{ height: 350, mt: 1 }}>
						<TableContainer component={Paper}
							sx={{
								padding: "0px 0px",
								borderRadius: "16px",
								fontSize: "1.0rem",
								width: 700
							}}>
							<Table sx={{ minWidth: 400 }} aria-label="customized table">
								<TableHead>
									<TableRow>
										<StyledTableCell align="center" width="13%">MBTI결과</StyledTableCell>
										<StyledTableCell align="center" width="13%">뇌파분포</StyledTableCell>
										<StyledTableCell align="center" width="8%">해당</StyledTableCell>
										<StyledTableCell align="center" width="66%">해석</StyledTableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{mbti_analysis_ret_detail_list.map((row) => (
										<StyledTableRow key={row.mbti_result}>
											{/* <StyledTableCell component="th" scope="row"> */}
											<StyledTableCell align="center">
												<Typography color="blue" variant='mbtieeg_table_mbti_type'>
													{/* <Typography color="blue"> */}
													{row.mbti_result}
												</Typography>
											</StyledTableCell>
											<StyledTableCell align="center">
												<Typography color="red" variant='mbtieeg_table_mbti_type_2'>
													{row.eeg_spectrum}
												</Typography>
											</StyledTableCell>
											<StyledTableCell align="center">{row.currect}</StyledTableCell>
											<StyledTableCell align="center">{row.desc}</StyledTableCell>
										</StyledTableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer >
					</Box>


					{/* Bottom Bar */}
					{/* <Box sx={{ height: 80, boxShadow: 3, 		backgroundColor: 'primary.main' }}> */}
					<Box sx={{
						height: 115, width: 800, boxShadow: 3,
						backgroundColor: '#bdd7ee'
					}}>
						<Stack
							direction="row"
							justifyContent="flex-start"
							alignItems="center"
							spacing={2}
							sx={{ height: 120, width: 700, ml: 3 }}
						>
							<Box sx={{ width: '120px', ml: 8 }}>
								<img className="photo"
									src={brainfactoryBottomLogo}
									alt="brain factory Inc. logo"
									width={70}
									height={70}
								/>
							</Box>
							{/* <Box> */}
							<ThemeProvider theme={theme}>
								<Typography variant="h8">
									Copyright@ 2023. 두뇌발전소 all rights reserved.<br></br>
									Copyright@ 2023. 두뇌발전소 all pictures cannot be copied without permission<br />
									이 결과지의 저작권은 두뇌발전소에게 있습니다.<br />
									두뇌발전소의 허락없이 이 결과지의 전부 또는 일부를 무단으로 사용, 변형 공개, 배포하는 행위를 금합니다.
								</Typography>
							</ThemeProvider>
							{/* </Box> */}

						</Stack>
					</Box>
					{/* </Box> */}
				</Stack>
			</Paper>
		</div >
	);
}

export default MbtiEegResultPage;