import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


const question_table_columns = [
    { id: 'question_id', label: '문의 번호', minWidth: 20, maxWidth: 40 },
    { id: 'userid', label: '유저', minWidth: 40, maxWidth: 60 },
    { id: 'question', label: '문의 내용', minWidth: 150, maxWidth: 200 },
    // { id: 'answer_state', label: '답변 상태', minWidth: 40, maxWidth: 60 },
    { id: 'answer', label: '답변', minWidth: 80, maxWidth: 100 },
];


function AdminQeustionPage() {
    const userid = sessionStorage.getItem('userid');

    // question table ==========================================================================
    const [questions, setQestions] = React.useState(100);


    React.useEffect(() => {
        fetch('/api/admin/question_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid })
        })
            .then((response) => {
                // 응답 데이터를 JSON으로 파싱
                return response.json();
            })
            .then((jsonData) => {
                // 성공적으로 데이터를 가져온 경우 상태 업데이트
                setQestions(jsonData);
                console.log(jsonData)
                console.log(questions.length)
            })
    }, []);// 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행


    const handleClick = (n) => {
        console.log('----' + n + '------')
        // console.log(questions[n].question)
        setQuestion(questions[n-1])
        console.log(questions[n-1].question_id);
        setQuestionOpen(true)
        // console.log(question.question)
    };

    const question_table = (
        <Paper elevation={2}>
            {/* <TableContainer sx={{ maxHeight: 440 , border: 1, borderColor: 'grey.300', borderRadius: 3}}> */}
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {question_table_columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{ backgroundColor: 'grey.300' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Array.isArray(questions) ?
                                questions.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {question_table_columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell onClick={() => handleClick(row.question_id)}
                                                        key={column.id} align={column.align}
                                                        style={{ maxWidth: 100 }}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                }) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
        
    // add question ==========================================================================
    const [question, setQuestion] = React.useState('');
    const [answer, setAnswer] = React.useState('');
    const [question_open, setQuestionOpen] = React.useState(false);
    // const [question_ok_open, setQuestionOkOpen] = React.useState(false);
    const [question_message, setQuestionMessage] = React.useState(false);
    const handleQuestionOpen = async () => {
        setQuestionMessage("");
        setQuestionOpen(true);
    }

    const handleQuestionClose = () => {
        setQuestionOpen(false);
    }

    const handleQuestionAddAnswer = async () => {
        console.log(question);
        const question_id = question.question_id;
        if (question == null) {
            setQuestionMessage("문의가 선택 되지 않았습니다.");
        } else {
            const response = await fetch('/api/admin/question_add_answer', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userid, question_id, answer }),
            });

            const tmp_data = await response.json();
            // setQuestionMessage(tmp_data.message);
            //console.log("tmp_data.id : " + tmp_data.id)
            console.log(tmp_data);
            // setQuestionOpen(false);
            alert("답변이 등록 되었습니다.");
            window.location.reload();
        }
    };
    // const handleQuestionAdd = async () => {
    //     console.log(question);
    //     if (question == null) {
    //         setQuestionMessage("문의 내용을 입력하세요");
    //     } else {
    //         const response = await fetch('/api/question_add', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ userid, question }),
    //         });

    //         const tmp_data = await response.json();
    //         setQuestionMessage(tmp_data.message);
    //         console.log("tmp_data.id : " + tmp_data.id)
    //         console.log(tmp_data);
    //         setQuestionOpen(false);
    //         alert("문의가 등록 되었습니다.");
    //         window.location.reload();
    //     }
    // };

    const select_question_dialog = (
        <div>
            <Dialog
                open={question_open}
                onClose={handleQuestionClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"답변 하기"}
                </DialogTitle>
                <Typography ml={2} mr={2}>
                    답변을 작성한 후 확인 버튼을 누르면 저장 됩니다. 
                </Typography>
                <DialogContent>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        mt={2}>
                        {/* {question.question} */}
                        <TextField label="질문 내용"
                            margin="normal"

                            variant="filled"
                            required fullWidth name="question" id="question"
                            // sx={{ width: 500 }}
                            multiline
                            rows={7}
                            InputProps={{
                                readOnly: true, // 읽기 전용으로 설정
                                // classes: {
                                //   root: classes.readOnlyTextField, // 스타일 적용
                                // },
                              }}
                            defaultValue={question.question}
                            >
                            
                        </TextField>
                        <TextField label="답변 내용"
                            margin="normal"

                            variant="filled"
                            required fullWidth name="question" id="question"
                            // sx={{ width: 500 }}
                            multiline
                            rows={7}
                            defaultValue={question.answer}
                            onChange={(e) => setAnswer(e.target.value)}>
                        </TextField>
                        <Typography>
                            {question_message}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleQuestionAddAnswer} size='small'>
                        등록
                    </Button>
                    <Button variant='contained' onClick={handleQuestionClose} size='small' autoFocus>
                        취소
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );

    return (
        <div>
            AdminQeustionPage
            {question_table}
            {select_question_dialog}
        </div>
    );
}


export default AdminQeustionPage;