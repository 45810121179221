import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import MbtiEegResultChart from './MbtiEegResultChart';
// import axios from 'axios';

// for print
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

// CircularProgress
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import MbtiEegResultPage from './MbtiEegResultPage';

const graph_data = [
    { name: 'E 외향', MBTI결과: 4, info: 'I 내향', 뇌파분포: 1 },
    { name: 'S 감각', MBTI결과: -6, info: 'N 직관', 뇌파분포: 15 },
    { name: 'T 사고', MBTI결과: -22, info: 'F 감정', 뇌파분포: 15 },
    { name: 'J 판단', MBTI결과: 22, info: 'P 인식', 뇌파분포: 15 },
];



function AdminMbtiEegPageTest() {
    const userid = sessionStorage.getItem('userid');
    const mbti_EI = [
        {
            value: 'Extroversion',
            label: 'E',
        },
        {
            value: 'Introversion',
            label: 'I',
        }
    ];

    const mbti_SN = [
        {
            value: 'Sensing',
            label: 'S',
        },
        {
            value: 'iNtuition',
            label: 'N',
        }
    ];


    const mbti_tf = [
        {
            value: 'Feeling',
            label: 'F',
        },
        {
            value: 'Thiking',
            label: 'T',
        }
    ];

    const mbti_JP = [
        {
            value: 'Judging',
            label: 'J',
        },
        {
            value: 'Perceiving',
            label: 'P',
        }
    ];


    // 초기에 구동되는 부분?
    React.useEffect(() => {

    }, []);// 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행


    const [mbti_EI_value, setMbtiEiValue] = React.useState(null);
    const [mbti_SN_value, setMbtiSnValue] = React.useState(null);
    const [mbti_TF_value, setMbtiFtValue] = React.useState(null);
    const [mbti_JP_value, setMbtiJpValue] = React.useState(null);

    const [mbti_eeg_EI_value, setMbtiEegEiValue] = React.useState(null);
    const [mbti_eeg_SN_value, setMbtiEegSnValue] = React.useState(null);
    const [mbti_eeg_TF_value, setMbtiEegFtValue] = React.useState(null);
    const [mbti_eeg_JP_value, setMbtiEegJpValue] = React.useState(null);


    const [mbti_eeg_src_id, setMbtiEegSrcID] = React.useState([0]);

    const [show_eeg_result_open, setShowEEGResultOpen] = React.useState(false);
    const [show_eeg_result_message, setShowEEGResultMessage] = React.useState(false);
    const [exam_client_name, setExamClientName] = React.useState('검사자명');

    const handleShowEEGResultPageOpen = () => {
        graph_data[0].MBTI결과 = mbti_EI_value;
        graph_data[1].MBTI결과 = mbti_SN_value;
        graph_data[2].MBTI결과 = mbti_TF_value;
        graph_data[3].MBTI결과 = mbti_JP_value;

        graph_data[0].뇌파분포 = mbti_eeg_EI_value;
        graph_data[1].뇌파분포 = mbti_eeg_SN_value;
        graph_data[2].뇌파분포 = mbti_eeg_TF_value;
        graph_data[3].뇌파분포 = mbti_eeg_JP_value;
        setShowEEGResultOpen(true)
    };

    const handleShowEEGResultPageClose = () => {
        setShowEEGResultOpen(false);
    };

    const handleMbtiEegRetPrint = () => {
        //     //show_eeg_result_id
        //     generatePDF(targetRef, { filename: mbti_eeg_save_filename })
    };

    const targetRef = useRef();

    const showEEGResultDialog = (
        <Dialog
            open={show_eeg_result_open}
            onClose={handleShowEEGResultPageClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    width: "100%",
                    maxWidth: "850px",  // Set your width here
                },
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {/* {"패스워드 변경"} */}
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
						
					</DialogContentText> */}
                <div ref={targetRef}>
                    {/* <MbtiEegResultPage mbti_eeg_src_id={mbti_eeg_src_id} user_id={userid} exam_client_name={exam_client_name} /> */}
                    {/* MBTI-결과표 */}
                    <Box sx={{
                        border: 1, borderRadius: '16px',
                        borderColor: '#bdd7ee', mt: 1
                    }}>
                        <MbtiEegResultChart data={graph_data} />
                    </Box>

                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' size='small'
                    onClick={handleMbtiEegRetPrint}>
                    PDF 다운로드
                </Button>
                {/* <Button variant='contained' size='small'
                    onClick={handleMbtiEegRetRemove}>
                    삭제
                </Button> */}
                <Button variant='contained' onClick={handleShowEEGResultPageClose} size='small' autoFocus>
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );



    return (
        <div>
            <Box
                sx={{
                    display: 'grid',
                    width: '100%'
                }}>
                <Box ml={3} mr={3} >
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={5}
                    >
                        <Box sx={{
                            mt: 0, ml: 0, height: 50
                        }}>
                            <h2>MBTI-EEG 데이터 처리</h2>
                        </Box>
                        <Paper elevation={3}>
                            <Box m={2}>
                                <h3>MBTI 결과 입력</h3>
                                <Box>
                                    <TableContainer component={Paper} sx={{ width: 400 }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField defaultValue="EI" />
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField defaultValue="SN" />
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField defaultValue="FT" />
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField defaultValue="JP" />
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_EI_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiEiValue(e.target.value)}
                                                        >
                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_SN_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiSnValue(e.target.value)}
                                                        >

                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_tf_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiFtValue(e.target.value)}
                                                        >

                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_JP_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiJpValue(e.target.value)}
                                                        >

                                                        </TextField>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Paper>

                        <Paper elevation={3}>
                            <Box m={2}>
                                <h3>MBTI-EEG 결과 입력</h3>
                                <Box>
                                    <TableContainer component={Paper} sx={{ width: 400 }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField defaultValue="EI" />
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField defaultValue="SN" />
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField defaultValue="FT" />
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField defaultValue="JP" />
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_EI_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiEegEiValue(e.target.value)}
                                                        >
                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_SN_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiEegSnValue(e.target.value)}
                                                        >

                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_tf_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiEegFtValue(e.target.value)}
                                                        >

                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_JP_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiEegJpValue(e.target.value)}
                                                        >

                                                        </TextField>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Paper>


                        <Box
                            sx={{
                                mt: 2,
                                display: 'flex',
                                // justifyContent: 'space-evenly'
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Button variant='contained' onClick={handleShowEEGResultPageOpen} size='small'>
                                결과 창보기
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            {showEEGResultDialog}
        </div>
    );
}


export default AdminMbtiEegPageTest;