import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

// import axios from 'axios';

// for print
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

// CircularProgress
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import MbtiEegResultPage from './MbtiEegResultPage';

function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" size={100} thickness={5} {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary">
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  
  CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
  };
  

function MbtiEegPage() {
    const userid = sessionStorage.getItem('userid');
    const mbti_EI = [
        {
            value: 'Extroversion',
            label: 'E',
        },
        {
            value: 'Introversion',
            label: 'I',
        }
    ];

    const mbti_SN = [
        {
            value: 'Sensing',
            label: 'S',
        },
        {
            value: 'iNtuition',
            label: 'N',
        }
    ];


    const mbti_tf = [
        {
            value: 'Feeling',
            label: 'F',
        },
        {
            value: 'Thiking',
            label: 'T',
        }
    ];

    const mbti_JP = [
        {
            value: 'Judging',
            label: 'J',
        },
        {
            value: 'Perceiving',
            label: 'P',
        }
    ];


    // 초기에 구동되는 부분?
    React.useEffect(() => {
        setMbtiEiSelect('Extroversion')
        setMbtiSnSelect('Sensing')
        setMbtiFtSelect('Feeling')
        setMbtiJpSelect('Judging')
    }, []);// 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행


    const [mbti_EI_select, setMbtiEiSelect] = React.useState(null);
    const [mbti_SN_select, setMbtiSnSelect] = React.useState(null);
    const [mbti_TF_select, setMbtiFtSelect] = React.useState(null);
    const [mbti_JP_select, setMbtiJpSelect] = React.useState(null);
    const [mbti_EI_value, setMbtiEiValue] = React.useState(null);
    const [mbti_SN_value, setMbtiSnValue] = React.useState(null);
    const [mbti_TF_value, setMbtiFtValue] = React.useState(null);
    const [mbti_JP_value, setMbtiJpValue] = React.useState(null);


    const [eeg_2min_file, setEEG2MinFile] = React.useState([]);
    const [showPlaceholder1, setShowPlaceholder1] = React.useState(true);
    const [eeg_3min_file, setEEG3MinFile] = React.useState([]);
    const [showPlaceholder2, setShowPlaceholder2] = React.useState(true);

    const [mbti_eeg_src_id, setMbtiEegSrcID] = React.useState([0]);


    const handleEEGFileDrop1 = (acceptedFiles) => {
        // 업로드된 파일들에 대한 처리를 여기에 구현
        console.log('Uploaded files:', acceptedFiles);
        // const names = acceptedFiles.map(file => file.name);
        setEEG2MinFile(acceptedFiles);
        // setEEG2MinFile(names);
        setShowPlaceholder1(false);
    };

    const handleEEGFileDrop2 = (acceptedFiles) => {
        // 업로드된 파일들에 대한 처리를 여기에 구현
        console.log('Uploaded files:', acceptedFiles);
        // const names = acceptedFiles.map(file => file.name);
        setEEG3MinFile(acceptedFiles);
        setShowPlaceholder2(false);
    };

    const handleCouponUse = async () => {
        const req_usecoupon_num = 1;
        fetch('/api/use_coupon', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid, req_usecoupon_num })
        })
            .then((response) => {
                // 응답 데이터를 JSON으로 파싱
                return response.json();
            })
            .then((jsonData) => {
                console.log(jsonData)
                // setBuyCoupon(Number(buy_coupon) + Number(req_buycoupon_num))
                // setCurCoupon(Number(cur_coupon) + Number(req_buycoupon_num))
                // 성공적으로 데이터를 가져온 경우 상태 업데이트
                // setUserData(jsonData);
                // setName(jsonData.name);
                // setPhone(phoneNumber(jsonData.phone));
                // setCurCoupon(jsonData.cur_coupon_cnt);
                // setUsedCoupon(jsonData.used_coupon_cnt);
                // setBuyCoupon(jsonData.buy_coupon_cnt);
        });
    }

    const handleEEGUpload2Min = async () => {
        console.log('Uploaded files:', eeg_2min_file[0]);
        const formData = new FormData();
        formData.append('file', eeg_2min_file[0])

        const response = await fetch('/api/eeg_upload_2min', {
            method: 'POST',
            body: formData
        })
        .then(response => response.json())
        .then(data => {
            console.log('2분검사 파일 업로드 성공:', data['message']);
            handleEEGUpload3Min()
            // alert(data['message']);
        })
        .catch(error => {
            console.error('2분검사 파일 업로드 에러:', error);
            alert('파일 업로드 에러');
            handleProgressStop();
        });

    }

    const handleEEGUpload3Min = async () => {
        const formData_3min = new FormData();
        formData_3min.append('file', eeg_3min_file[0])
        const response = await fetch('/api/eeg_upload_3min', {
            method: 'POST',
            body: formData_3min
        })
            .then(response => response.json())
            .then(data => {
                console.log('3분검사 파일 업로드 성공:', data['message']);

                handleEEGUAnalysis()
                // alert(data['message']);
            })
            .catch(error => {
                console.error('3분검사 파일 업로드 에러:', error);
                alert('파일 업로드 에러');
                handleProgressStop();
            });

    }

    const handleEEGUAnalysis = async () => {
        let use_mbti = false
        console.log(mbti_EI_value)
        console.log(mbti_SN_value)
        console.log(mbti_TF_value)
        console.log(mbti_JP_value)
        if (mbti_EI_value != null) {
            if (mbti_SN_value != null) {
                if (mbti_TF_value != null) {
                    if (mbti_JP_value != null) {
                        use_mbti = true
                    }
                }
            }
        }

        const response = await fetch('/api/eeg_analysis', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // userid
                userid, use_mbti,
                mbti_EI_select, mbti_EI_value,
                mbti_SN_select, mbti_SN_value,
                mbti_TF_select, mbti_TF_value,
                mbti_JP_select, mbti_JP_value
            }),
        })
            .then((response) => {
                // 응답 데이터를 JSON으로 파싱
                console.log('parsing')
                return response.json();
            })
            .then((jsonData) => {
                // 성공적으로 데이터를 가져온 경우 상태 업데이트
                // alert(jsonData['message']);

                handleCouponUse();

                const cur_coupon = sessionStorage.getItem('cur_coupon')
                sessionStorage.setItem('cur_coupon', cur_coupon -1);
                // console.log(jsonData)
                setMbtiEegSrcID(jsonData['mbti_eeg_src_id']);
                setExamClientName(jsonData['exam_client_name']);
                setMbtiEegSaveFilename('MBTI결과-뇌파분포 비교_' + jsonData['exam_client_name'] + '_' + jsonData['exam_date_str'] + '.pdf')
                // setShowEEGResultOpen(true);
            })
            .catch(error => {
                console.error('분석 실패:', error);
                alert('분석 에러 : ');
                handleProgressStop();
            });
    };


    const handleEEGAnalysis = async () => {
        let is_exit = false
        let use_mbti = false
        if (mbti_EI_value != "") {
            if (mbti_SN_value != "") {
                if (mbti_TF_value != "") {
                    if (mbti_JP_value != "") {
                        use_mbti = true
                    }
                }
            }
        }

        const cur_coupon = sessionStorage.getItem('cur_coupon');
        if ( cur_coupon <= 0){
            console.log(cur_coupon);
            alert('쿠폰이 부족 합니다.')
            is_exit = true
        } else {
            if (use_mbti) {
                if (mbti_EI_value < 0 || mbti_EI_value > 30) {
                    alert('에러 : E-I 값을 1~30 사이로 입력하세요')
                    is_exit = true
                } else if (mbti_SN_value < 0 || mbti_SN_value > 30) {
                    alert('에러 : S-N 값을 1~30 사이로 입력하세요')
                    is_exit = true
                } else if (mbti_TF_value < 0 || mbti_TF_value > 30) {
                    alert('에러 : T-F 값을 1~30 사이로 입력하세요')
                    is_exit = true
                } else if (mbti_JP_value < 0 || mbti_JP_value > 30) {
                    alert('에러 : J-P 값을 1~30 사이로 입력하세요')
                    is_exit = true
                }
            }

            if (eeg_2min_file[0] == null) {
                alert('에러 : 2분 검사 파일을 선택하세요')
                is_exit = true
            } else if (eeg_3min_file[0] == null) {
                alert('에러 : 3분 검사 파일을 선택하세요')
                is_exit = true
            }
        }

        handleCheckEEGAnalysisClose()
        if (is_exit == false) {
            handleProgressStart();
            handleCheckEEGAnalysisClose()

            handleEEGUpload2Min()
        // => 3Min and Analysis
        }
    };

    const [progress, setProgress] = React.useState(0);
    const [showCircularProgress, setShowCircularProgress] = React.useState(false);
    const [runCircularProgress, setRunCircularProgress] = React.useState(false);
  

    const handleProgressStart = () => {
        setRunCircularProgress(true);
        setShowCircularProgress(true);
    }

    const handleProgressStop = () => {
        setRunCircularProgress(false);
        setShowCircularProgress(false);
    }



    React.useEffect(() => {
        let timer;
        if (showCircularProgress) {
            if (runCircularProgress) {
                timer = setInterval(() => {
                    setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
                }, 200);
            }
        }
        return () => {
            clearInterval(timer);
        };
    }, [showCircularProgress, runCircularProgress]);

    React.useEffect(() => {
        // console.log('2-progress : ' + progress );
        if (progress >= 100) {
            // console.log('hide progressCircular')
            setRunCircularProgress(false);
            const hideProgressTimer = setTimeout(() => {
                setShowCircularProgress(false);
                setProgress(0);
                setShowEEGResultOpen(true);
            }, 1000); // 2초 후에 CircularWithValueLabel을 숨깁니다.
            return () => clearTimeout(hideProgressTimer);
        }
    }, [progress]);

    const progress_dialog =(
        <div>
            <Dialog
                open={showCircularProgress}
                // onClose={handleCheckEEGAnalysisClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"MBTI-EEG 데이터 처리중 ..."}
                </DialogTitle>

                <DialogContent>
                    {/* {showCircularProgress && <CircularProgressWithLabel value={progress} />} */}
                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}>
                        <CircularProgressWithLabel value={progress} />
                    </Stack>
                </DialogContent>
            </Dialog>
        </div>
    )

    const [check_eeg_analysis_open, setCheckEEGAnalysisOpen] = React.useState(false);

    const handleCheckEEGAnalysisOpen = async () => {
        setCheckEEGAnalysisOpen(true);
    }

    const handleCheckEEGAnalysisClose = () => {
        setCheckEEGAnalysisOpen(false);
    }


    const check_eeg_analysis_dialog = (
        <div>
            <Dialog
                open={check_eeg_analysis_open}
                onClose={handleCheckEEGAnalysisClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"분석 진행"}
                </DialogTitle>
                <Typography ml={2} mr={2}>
                    쿠폰이 차감됩니다. 계속 진행하시겠습니까?<br/>
                </Typography>
                <DialogContent>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        mt={2}>


                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleEEGAnalysis} size='small'>
                        분석 진행
                    </Button>
                    <Button variant='contained' onClick={handleCheckEEGAnalysisClose} size='small' autoFocus>
                        취소
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

    const handleCheckEEGAnalysis = async() => {
        setCheckEEGAnalysisOpen(true);
    };

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: handleEEGFileDrop1,
        accept: '*',  // 허용할 파일 유형을 여기에 지정
        // accept: 'image/*',  // 허용할 파일 유형을 여기에 지정
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: handleEEGFileDrop2,
        accept: '*',  // 허용할 파일 유형을 여기에 지정
        // accept: 'image/*',  // 허용할 파일 유형을 여기에 지정
    });


    const eeg_file_upload_box = (
        <Box m={2}>
            {/* <div {...getRootProps()} style={dropzoneStyles}>
                <input {...getInputProps()} />
                {showPlaceholder1 ? 
                    (
                        <p>2분검사 파일을 이곳으로 끌어놓거나 클릭하세요</p>
                    ) : (
                        <p>{eeg_file_name_1}</p>
                    )
                }
            </div> */}

            <h3>뇌파측정 결과 업로드</h3>

            2분 검사 파일
            <div {...getRootProps1()} style={dropzoneStyles}>
                <input {...getInputProps1()} />
                {showPlaceholder1 ?
                    (
                        <p>2분검사 파일을 이곳으로 끌어놓거나 클릭하세요</p>
                    ) : (
                        // <p>{eeg_file_name_1.map((name, index) => (
                        //     <div key={index}>{name}</div>
                        // ))}</p>
                        // <p>{eeg_2min_file.name}</p>
                        <p>{eeg_2min_file.map(file => file.name)}</p>
                    )
                }
            </div>
            <br />
            3분 검사 파일
            <div {...getRootProps2()} style={dropzoneStyles}>
                <input {...getInputProps2()} />
                {showPlaceholder2 ?
                    (
                        <p>3분 검사 파일을 이곳으로 끌어놓거나 클릭하세요</p>
                    ) : (
                        <p>{eeg_3min_file.map(file => file.name)}</p>
                    )
                }
            </div>

        </Box>
    );


    const [show_eeg_result_open, setShowEEGResultOpen] = React.useState(false);
    const [show_eeg_result_message, setShowEEGResultMessage] = React.useState(false);
    const [exam_client_name, setExamClientName] = React.useState('검사자명');
    const [mbti_eeg_save_filename, setMbtiEegSaveFilename] = React.useState('MBTI뇌파분포비교.pdf');

    const handleShowEEGResultPageOpen = () => {
        setShowEEGResultOpen(true)
    };

    const handleShowEEGResultPageClose = () => {
        setShowEEGResultOpen(false);
    };

    const handleMbtiEegRetPrint = () => {
        //     //show_eeg_result_id
        console.log(mbti_eeg_save_filename)
        //     generatePDF(targetRef, { filename: mbti_eeg_save_filename })
    };

    const targetRef = useRef();

    const showEEGResultDialog = (
        <Dialog
            open={show_eeg_result_open}
            onClose={handleShowEEGResultPageClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    width: "100%",
                    maxWidth: "850px",  // Set your width here
                },
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {/* {"패스워드 변경"} */}
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
						
					</DialogContentText> */}
                <div ref={targetRef}>
                    <MbtiEegResultPage mbti_eeg_src_id={mbti_eeg_src_id} user_id={userid} exam_client_name={exam_client_name} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' size='small'
                    onClick={handleMbtiEegRetPrint}>
                    PDF 다운로드
                </Button>
                {/* <Button variant='contained' size='small'
                    onClick={handleMbtiEegRetRemove}>
                    삭제
                </Button> */}
                <Button variant='contained' onClick={handleShowEEGResultPageClose} size='small' autoFocus>
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );


    return (
        <div>
            <Box
                sx={{
                    display: 'grid',
                    width: '100%'
                }}>
                <Box ml={3} mr={3} >
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={5}
                    >
                        <Box sx={{
                            mt: 0, ml: 0, height: 50
                        }}>
                            <h2>MBTI-EEG 데이터 처리</h2>
                        </Box>
                        <Paper elevation={3}>
                            <Box m={2}>
                                <h3>MBTI 결과 입력</h3>
                                <Box>
                                    <TableContainer component={Paper} sx={{ width: 400 }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField
                                                            id="outlined-select-currency"
                                                            select
                                                            defaultValue="Extroversion"
                                                            onChange={(e) => setMbtiEiSelect(e.target.value)}
                                                        >
                                                            {mbti_EI.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField
                                                            id="outlined-select-currency"
                                                            select
                                                            defaultValue="Sensing"
                                                            onChange={(e) => setMbtiSnSelect(e.target.value)}
                                                        >
                                                            {mbti_SN.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField
                                                            id="outlined-select-currency"
                                                            select
                                                            defaultValue="Feeling"
                                                            onChange={(e) => setMbtiFtSelect(e.target.value)}
                                                        >
                                                            {mbti_tf.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell sx={{ backgroundColor: 'grey.300' }}>
                                                        <TextField
                                                            id="outlined-select-currency"
                                                            select
                                                            defaultValue="Judging"
                                                            onChange={(e) => setMbtiJpSelect(e.target.value)}
                                                        >
                                                            {mbti_JP.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_EI_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiEiValue(e.target.value)}
                                                        >
                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_SN_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiSnValue(e.target.value)}
                                                        >

                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_tf_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiFtValue(e.target.value)}
                                                        >

                                                        </TextField>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextField
                                                            id='mbti_JP_textfield'
                                                            type='Number'
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            onChange={(e) => setMbtiJpValue(e.target.value)}
                                                        >

                                                        </TextField>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        </Paper>
                        <Paper elevation={3}>
                            {eeg_file_upload_box}
                        </Paper>

                        <Box
                            sx={{
                                mt: 2,
                                display: 'flex',
                                // justifyContent: 'space-evenly'
                                justifyContent: 'flex-end',
                            }}
                        >

                            {/* <Button variant='contained' onClick={handleEEGAnalysis} size='small'> */}
                            <Button variant='contained' onClick={handleCheckEEGAnalysis} size='small'>
                                분석 진행
                            </Button>
                            <Button variant='contained' onClick={handleShowEEGResultPageOpen} size='small'>
                                결과 창보기
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            {showEEGResultDialog}
            {check_eeg_analysis_dialog}
            {progress_dialog}

        </div>
    );
}

const dropzoneStyles = {
    border: '2px dashed #cccccc',
    borderRadius: '4px',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
};

export default MbtiEegPage;