import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceArea } from 'recharts';
// import backgroundImage from './bg_img.png'; // Replace with the actual path to your image
import Box from '@mui/material/Box';

// import './bg_img.png';

const XAxisTextStyle = {
	fontSize: 10, // Adjust the font size as needed
};

const YAxisTextStyle = {
	fontSize: 10, // Adjust the font size as needed
};

const BarTextStyle = {
	fontSize: 10, // Adjust the font size as needed
};



const CustomXAxisTick = (props) => {
	const { x, y, payload } = props;
	const value = String(payload.value).replace('-', ''); // Convert to string and remove '-'
	return <text x={x} y={y} dy={16} textAnchor="middle" style={XAxisTextStyle}>{value}</text>;
};

//   const CustomLabel = ({ viewBox, value }) => {
// 	const { x, y} = viewBox;
// 	let textAnchor = 'start';

// 	const displayValue = Math.abs(value); // Remove the '-' sign
// 	return <text x={x + value * 9 - 4} y={y} dy={+15} fill="white" textAnchor={textAnchor} >{displayValue}</text>;
//   };

//   const CustomLabel2 = ({ viewBox, value }) => {
// 	const { x, y} = viewBox;
// 	let textAnchor = 'start';

// 	const displayValue = Math.abs(value); // Remove the '-' sign
// 	return <text x={x + value * 9 - 4} y={y} dy={+15} fill="yellow" textAnchor={textAnchor} >{displayValue}</text>;
//   };




const CustomLabel = ({ viewBox, value }) => {
	const { x, y, width, height } = viewBox;
	const displayValue = Math.abs(value); // Remove the '-' sign

	let move_x = 0
	if (value < 0) {
		move_x = 0
	} else {
		move_x = -10
	}

	return (
		<text x={x + width * 0.9 + move_x} y={y + height / 2} dy={4}
			fill="white" textAnchor="start" style={BarTextStyle}>
			{displayValue}
		</text>
	);
};


const MbtiEegResultChart = (props) => {
	for (var i = 0; i < 4; i++) {
		if (props.data[i].MBTI결과 == 0 || props.data[i].MBTI결과 == null)
			props.data[i].MBTI결과 = 1

		if (props.data[i].뇌파분포 == 0 || props.data[i].뇌파분포 == null)
			props.data[i].뇌파분포 = 1
	}


	console.log(props.data[0])
	return (
		//실제  그래프는 670x180이 됨
		// <div style={containerStyle}> 
		<div>
			<Box sx={{ mt: 2, mb: 2 }}>
				<BarChart width={700} height={200} data={props.data} layout="vertical" >
					<XAxis type="number" domain={[-30, 30]} tickCount={15} tick={<CustomXAxisTick />} />
					<YAxis yAxisId="left" dataKey="name" type="category" orientation="left" label={<CustomLabel position="right" />} />
					<YAxis yAxisId="right" dataKey="info" type="category" orientation="right" />
					<CartesianGrid strokeDasharray="3 3" />
					{/* <Tooltip /> */}
					<Legend />
					{/* <Bar dataKey="MBTI결과" fill="#8884d8" label={<CustomLabel position="right" />} yAxisId="left" /> */}
					<Bar dataKey="MBTI결과" fill="#3368f4" label={<CustomLabel position="right" />} yAxisId="left" />
					{/* <Bar dataKey="뇌파분포" fill="#82ca9d" label={<CustomLabel position="right" />} yAxisId="left" /> */}
					<Bar dataKey="뇌파분포" fill="#ff4aab" label={<CustomLabel position="right" />} yAxisId="left" />
				</BarChart>
			</Box>
		</div>
	);

};

export default MbtiEegResultChart;