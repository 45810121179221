// import React, { useState, useEffect } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


const question_table_columns = [
    { id: 'question_id', label: '문의 번호', minWidth: 40, maxWidth: 60 },
    { id: 'question', label: '문의 내용', minWidth: 150, maxWidth: 200 },
    { id: 'answer', label: '답변', minWidth: 150, maxWidth: 200 },
];



function phoneNumber(value) {
    value = value.replace(/[^0-9]/g, "");
    return value.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
        "$1-$2-$3"
    );
}

// cur_coupon_cnt = db.Column(db.Integer, nullable=False)
// used_coupon_cnt = db.Column(db.Integer, nullable=False)
// buy_coupon_cnt = db.Column(db.Integer, nullable=False)

function MyPage() {
    const userid = sessionStorage.getItem('userid');
    // const [userdata, setUserData] = useState(null);

    const [name, setName] = React.useState(null);
    const [phone, setPhone] = React.useState(null);
    const [cur_coupon, setCurCoupon] = React.useState(null);
    const [used_coupon, setUsedCoupon] = React.useState(null);
    const [buy_coupon, setBuyCoupon] = React.useState(null);

    // useEffect를 사용하여 컴포넌트가 마운트될 때 데이터를 가져옴
    React.useEffect(() => {
        fetch('/api/userinfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid })
        })
            .then((response) => {
                // 응답 데이터를 JSON으로 파싱
                return response.json();
            })
            .then((jsonData) => {
                // 성공적으로 데이터를 가져온 경우 상태 업데이트
                // setUserData(jsonData);
                setName(jsonData.name);
                setPhone(phoneNumber(jsonData.phone));
                setCurCoupon(jsonData.cur_coupon_cnt);
                setUsedCoupon(jsonData.used_coupon_cnt);
                setBuyCoupon(jsonData.buy_coupon_cnt);
                sessionStorage.setItem('cur_coupon',jsonData.cur_coupon_cnt);
            })
    }, []);// 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행

    // modify userinfo ==========================================================================
    const [userinfo_name, setUserInfoName] = React.useState(name);
    const [userinfo_phone, setUserInfoPhone] = React.useState(phone);
    const [modify_userinfo_open, setModifyUserInfoOpen] = React.useState(false);
    const [modify_userinfo_message, setModifyUserInfoMessage] = React.useState(false);
    const handleModifyUserInfoOpen = () => {
        setModifyUserInfoMessage("");
        setUserInfoName(name);
        setUserInfoPhone(phone);
        setModifyUserInfoOpen(true);
    };

    const handleModifyUserInfoClose = () => {
        setModifyUserInfoOpen(false);
    };


    const handleModifyUserInfo = async () => {
        // console.log(userinfo);
        // console.log(userinfo_confirm);
        const response = await fetch('/api/change_userinfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid, userinfo_name, userinfo_phone }),
        });

        const tmp_data = await response.json();
        setModifyUserInfoMessage(tmp_data.message);
        console.log(tmp_data);
        window.location.reload();
    };

    const modify_userinfo_dialog = (
        <Dialog
            open={modify_userinfo_open}
            onClose={handleModifyUserInfoClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"사용자 정보 변경"}
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
						
					</DialogContentText> */}
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    mt={2}>
                    <TextField label="사용자 명" 
                        margin="normal"
                        defaultValue={name}
                        onChange={(e) => setUserInfoName(e.target.value)} >
                    </TextField>

                    <TextField label="전화번호"
                        margin="normal"
                        defaultValue={phone}
                        onChange={(e) => setUserInfoPhone(e.target.value)}>
                    </TextField>
                    <Typography>
                        {modify_userinfo_message}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleModifyUserInfo} size='small'>
                    수정
                </Button>
                <Button variant='contained' onClick={handleModifyUserInfoClose} size='small' autoFocus>
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );


    // modify password ==========================================================================
    const [password, setPassword] = React.useState('');
    const [password_confirm, setPasswordConfirm] = React.useState('');
    const [modify_password_open, setModifyPasswordOpen] = React.useState(false);
    const [modify_password_message, setModifyPasswordMessage] = React.useState(false);
    const handleModifyPasswordOpen = () => {
        setModifyPasswordMessage("");
        setModifyPasswordOpen(true);
    };

    const handleModifyPasswordClose = () => {
        setModifyPasswordOpen(false);
    };


    const handleModifyPassword = async () => {
        console.log(password);
        console.log(password_confirm);
        if (password !== password_confirm) {
            setModifyPasswordMessage("입력한 비밀번호가 같지 않습니다");
        } else {
            const response = await fetch('/api/change_password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userid, password }),
            });

            const tmp_data = await response.json();
            setModifyPasswordMessage(tmp_data.message);
            console.log(tmp_data);
        }
    };

    const modify_password_dialog = (
        <Dialog
            open={modify_password_open}
            onClose={handleModifyPasswordClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"패스워드 변경"}
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
						
					</DialogContentText> */}
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    mt={2}>
                    <TextField label="Password" type="password"
                        margin="normal"
                        required fullWidth name="password" id="password"
                        onChange={(e) => setPassword(e.target.value)} >
                    </TextField>

                    <TextField label="Password Confirm" type="password"
                        margin="normal"
                        required fullWidth name="password" id="password_confirm"
                        onChange={(e) => setPasswordConfirm(e.target.value)}>
                    </TextField>
                    <Typography>
                        {modify_password_message}
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' onClick={handleModifyPassword} size='small'>
                    수정
                </Button>
                <Button variant='contained' onClick={handleModifyPasswordClose} size='small' autoFocus>
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );

    // question table ==========================================================================
    const [questions, setQestions] = React.useState(100);

    React.useEffect(() => {
        fetch('/api/question_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid })
        })
            .then((response) => {
                // 응답 데이터를 JSON으로 파싱
                return response.json();
            })
            .then((jsonData) => {
                // 성공적으로 데이터를 가져온 경우 상태 업데이트
                setQestions(jsonData);
                console.log(jsonData)
                console.log(questions.length)
            })
    }, []);// 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행


    const handleClick = (n) => {
        console.log('----' + n + '------')
    };

    const question_table = (
        <Paper elevation={2}>
            {/* <TableContainer sx={{ maxHeight: 440 , border: 1, borderColor: 'grey.300', borderRadius: 3}}> */}
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {question_table_columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{ backgroundColor: 'grey.300' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Array.isArray(questions) ?
                                questions.map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {question_table_columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell onClick={() => handleClick(row.question_id)}
                                                        key={column.id} align={column.align}
                                                        style={{ maxWidth: 100 }}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                }) : null}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );

    // add question ==========================================================================
    const [question, setQuestion] = React.useState('');
    const [question_open, setQuestionOpen] = React.useState(false);
    // const [question_ok_open, setQuestionOkOpen] = React.useState(false);
    const [question_message, setQuestionMessage] = React.useState(false);
    const handleQuestionOpen = async () => {
        setQuestionMessage("");
        setQuestionOpen(true);
    }

    const handleQuestionClose = () => {
        setQuestionOpen(false);
    }

    const handleQuestionAdd = async () => {
        console.log(question);
        if (question == null) {
            setQuestionMessage("문의 내용을 입력하세요");
        } else {
            const response = await fetch('/api/question_add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userid, question }),
            });

            const tmp_data = await response.json();
            setQuestionMessage(tmp_data.message);
            console.log("tmp_data.id : " + tmp_data.id)
            console.log(tmp_data);
            setQuestionOpen(false);
            alert("문의가 등록 되었습니다.");
            window.location.reload();
        }
    };

    const add_question_dialog = (
        <div>
            <Dialog
                open={question_open}
                onClose={handleModifyPasswordClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"문의 하기"}
                </DialogTitle>
                <Typography ml={2} mr={2}>
                    문의 하신 내용을 담당자가 확인 하고 답변을 드립니다. <br />
                    답변은 마이 페이지의 나의 문의 내역에서 확인 할 수 있습니다.
                </Typography>
                <DialogContent>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        mt={2}>

                        <TextField label="문의 내용"
                            margin="normal"

                            variant="filled"
                            required fullWidth name="question" id="question"
                            // sx={{ width: 500 }}
                            multiline
                            rows={7}
                            onChange={(e) => setQuestion(e.target.value)}>
                        </TextField>
                        <Typography>
                            {question_message}
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={handleQuestionAdd} size='small'>
                        등록
                    </Button>
                    <Button variant='contained' onClick={handleQuestionClose} size='small' autoFocus>
                        취소
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );


    // coupon state ==========================================================================
    const [buy_coupon_open, setBuyCouponOpen] = React.useState(false);
    const [req_buycoupon_num, setReqBuyCouponNum] = React.useState(false);
    const [req_buycoupon_price, setReqBuyCouponPrice] = React.useState(false);

    const handleBuyCouponOpen = async () => {
        setQuestionMessage("");
        setBuyCouponOpen(true);
    }

    const handleBuyCouponClose = () => {

        setBuyCouponOpen(false);
    }


    const handleBuyCoupon = async () => {
        console.log("BuyCoupon");
        fetch('/api/buy_coupon', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid, req_buycoupon_num })
        })
            .then((response) => {
                // 응답 데이터를 JSON으로 파싱
                return response.json();
            })
            .then((jsonData) => {
                console.log(jsonData)
                setBuyCoupon(Number(buy_coupon) + Number(req_buycoupon_num))
                setCurCoupon(Number(cur_coupon) + Number(req_buycoupon_num))
                // 성공적으로 데이터를 가져온 경우 상태 업데이트
                // setUserData(jsonData);
                // setName(jsonData.name);
                // setPhone(phoneNumber(jsonData.phone));
                // setCurCoupon(jsonData.cur_coupon_cnt);
                // setUsedCoupon(jsonData.used_coupon_cnt);
                // setBuyCoupon(jsonData.buy_coupon_cnt);
            })
        // if (question == null) {
        //     setQuestionMessage("문의 내용을 입력하세요");
        // } else {
        //     const response = await fetch('/api/question_add', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ userid, question }),
        //     });

        //     const tmp_data = await response.json();
        //     setQuestionMessage(tmp_data.message);
        //     console.log("tmp_data.id : " + tmp_data.id)
        //     console.log(tmp_data);
        //     setQuestionOpen(false);
        // }
    };


    React.useEffect(() => {
        if (req_buycoupon_num < 10) {
            // setReqBuyCouponPrice(req_buycoupon_num*7300)
            // const price = req_buycoupon_num * 7300
            const price = req_buycoupon_num * 22000
            setReqBuyCouponPrice(price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        } else if (req_buycoupon_num < 100) {
            // const price = req_buycoupon_num * 6800
            const price = req_buycoupon_num * 19800
            setReqBuyCouponPrice(price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        } else {
            // const price = req_buycoupon_num * 6500
            const price = req_buycoupon_num * 17600
            setReqBuyCouponPrice(price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))
        }
    }, [req_buycoupon_num]);

    const buy_coupon_dialog = (
        <div>
            <Dialog
                open={buy_coupon_open}
                onClose={handleBuyCouponClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"쿠폰 구매"}
                </DialogTitle>
                <Typography ml={2} mr={2}>
                    결제 시스템 준비중입니다.<br/>
                    관리자를 통해 구매를 진행해주세요.
                    {/* 안내에 따라 구매 결재를 진행 하세요 */}
                </Typography>
                <DialogContent>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={2}
                        mt={2}>


                    </Stack>
                </DialogContent>
                <DialogActions>
                    {/* <Button variant='contained' onClick={handleBuyCoupon} size='small'>
                        구매 진행
                    </Button> */}
                    <Button variant='contained' onClick={handleBuyCouponClose} size='small' autoFocus>
                        확인
                        {/* 취소 */}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )

    const coupon_table = (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ backgroundColor: 'grey.300' }}>구매한 쿠폰</TableCell>
                        <TableCell sx={{ backgroundColor: 'grey.300' }}>사용한 쿠폰</TableCell>
                        <TableCell sx={{ backgroundColor: 'grey.300' }}>잔여 쿠폰</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>{buy_coupon}</TableCell>
                        <TableCell>{used_coupon}</TableCell>
                        <TableCell>{cur_coupon}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )

    const coupon_price_table = (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {/* <TableCell sx={{ backgroundColor: 'grey.300'}}>갯수</TableCell> */}
                        <TableCell sx={{ backgroundColor: 'grey.300' }}>1~9개</TableCell>
                        <TableCell sx={{ backgroundColor: 'grey.300' }}>10~99개</TableCell>
                        <TableCell sx={{ backgroundColor: 'grey.300' }}>100개 이상</TableCell>
                        <TableCell sx={{ backgroundColor: 'grey.500' }}>신청 부수</TableCell>
                        <TableCell sx={{ backgroundColor: 'grey.500' }}>결재 금액</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {/* <TableCell sx={{ backgroundColor: 'grey.200'}}>1개당 가격</TableCell> */}
                        {/* <TableCell>7,300 원</TableCell>
                        <TableCell>6,800 원</TableCell>
                        <TableCell>6,500 원</TableCell>
                         */}
                        <TableCell>22,000 원</TableCell>
                        <TableCell>19,800 원</TableCell>
                        <TableCell>17,600 원</TableCell>
                        <TableCell>
                            <TextField
                                id='buy_coupon_num'
                                type='Number'
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setReqBuyCouponNum(e.target.value)}
                            >
                            </TextField>
                        </TableCell>
                        <TableCell>
                            {/* <TextField
                                id='buy_coupon_price'
                                InputProps={{
                                    readOnly: true
                                }}
                            > */}
                            {req_buycoupon_price} 원
                            {/* </TextField> */}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )



    return (
        <div>
            <Box
                sx={{
                    display: 'grid',
                    width: '100%'
                }}>
                <Box ml={3} mr={3} >
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={3}
                    >
                        <Box sx={{
                            mt: 0, ml: 0, height: 50
                        }}>
                            <h2>마이 페이지</h2>
                        </Box>
                        <Stack>
                            <Paper elevation={3}>
                                <Box m={2}>
                                    <h3>회원 정보</h3>
                                    <Typography variant="subtitle2" gutterBottom>
                                        &nbsp;&nbsp;ID : {userid} <br />
                                        &nbsp;&nbsp;사용자 : {name}<br />
                                        &nbsp;&nbsp;연락처 : {phone}<br />
                                    </Typography>
                                </Box>
                            </Paper>
                            <Stack   
                                    direction="row"
                                    justifyContent="flex-end"
                                    // alignItems="flex-start"
                                    alignItems="centor"
                                    spacing={2}
                                    sx={{ mt: 1}}
                                    >
                                <Button variant='contained' size='small'
                                    sx={{ float: 'right', mt: 1 }}
                                    onClick={handleModifyUserInfoOpen}>
                                    사용자 정보 변경
                                </Button>
                                {modify_userinfo_dialog}
                                <Button variant='contained' size='small'
                                    sx={{ float: 'right', mt: 1 }}
                                    onClick={handleModifyPasswordOpen}>
                                    패스워드 변경
                                </Button>
                                {modify_password_dialog}
                            </Stack>
                        </Stack>
                        <Stack>
                            <Paper elevation={3}>
                                <Box m={2}>
                                    <h3>나의 문의 내역</h3>
                                    {question_table}
                                </Box>
                            </Paper>
                            <Box>
                                <Button variant='contained' size='small'
                                    sx={{ float: 'right', mt: 1 }}
                                    onClick={handleQuestionOpen}>
                                    문의 하기
                                </Button>
                                {add_question_dialog}
                                {/* {add_question_ok_dialog} */}
                            </Box>
                        </Stack>
                        <Stack>
                            <Paper elevation={3}>
                                <Box m={2}>
                                    <h3>쿠폰 현황</h3>
                                    {coupon_table}
                                </Box>
                            </Paper>
                        </Stack>
                        <Stack>
                            <Paper elevation={3}>
                                <Box m={2}>
                                    <h3>쿠폰 가격표</h3>
                                    {coupon_price_table}
                                </Box>
                            </Paper>
                            <Box>
                                <Button variant='contained' size='small'
                                    sx={{ float: 'right', mt: 1 }}
                                    onClick={handleBuyCouponOpen}>
                                    쿠폰 구매
                                </Button>
                                {buy_coupon_dialog}
                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
        </div >
    );
}

export default MyPage;