import * as React from 'react';

// for print
import { useRef } from 'react';
import generatePDF from 'react-to-pdf';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';


import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { Typography } from '@mui/material';

// Table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
//Table sorting
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import MbtiEegResultPage from './MbtiEegResultPage';

const history_table_columns = [
    { id: 'mbti_eeg_id', label: '번호', minWidth: 40, maxWidth: 60 },
    // { id: 'userid', label: '유저', minWidth: 40, maxWidth: 60 },
    { id: 'exam_client_name', label: '검사자 이름', minWidth: 100, maxWidth: 200 },
    { id: 'exam_date_str', label: '검사일', minWidth: 100, maxWidth: 150 },
    { id: 'analysis_date', label: '분석일', minWidth: 150, maxWidth: 200 },
];

HistoryTableHeader.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function HistoryTableToolbar(props) {
    const { numSelected } = props;

    return (
        <Toolbar
        // sx={{
        //     pl: { sm: 2 },
        //     pr: { xs: 1, sm: 1 },
        //     ...(numSelected > 0 && {
        //     bgcolor: (theme) =>
        //         alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        //     }),
        // }}
        >
            {/* {numSelected > 0 ? (
            <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
            >
            {numSelected} selected
            </Typography>
        ) : (
            <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
            >
            Nutrition
            </Typography>
        )} */}
            {/* {numSelected > 0 ? (
            <Tooltip title="Delete">
            <IconButton>
                <DeleteIcon />
            </IconButton>
            </Tooltip>
        ) : (
            <Tooltip title="Filter list">
            <IconButton>
                <FilterListIcon />
            </IconButton>
            </Tooltip>
        )} */}
        </Toolbar>
    );
}

HistoryTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function HistoryTableHeader(props) {
    const { order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        // onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell> */}
                {history_table_columns.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={{ backgroundColor: 'grey.300' }}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead >
    );
}

function HistoryPage() {
    const [mbti_eeg_srcs, setMbtiEegSrcs] = React.useState(null);
    const userid = sessionStorage.getItem('userid');
    const [exam_client_name, setExamClientName] = React.useState('검사자명');
    const [mbti_eeg_save_filename, setMbtiEegSaveFilename] = React.useState('MBTI뇌파분포비교.pdf');


    React.useEffect(() => {
        fetch('/api/eeg_src_list', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userid })
        })
            .then((response) => {
                // 응답 데이터를 JSON으로 파싱
                return response.json();
            })
            .then((jsonData) => {
                // 성공적으로 데이터를 가져온 경우 상태 업데이트
                // setMbtiEegSrcs(jsonData);

                // const tmpSrcs = JSON.parse(jsonData);
                console.log('----parse json to javascript list--------')
                console.log(jsonData)
                var result = Object.values(jsonData);

                result.sort((a, b) => b.mbti_eeg_id - a.mbti_eeg_id)
                setMbtiEegSrcs(result);
                console.log(result)


            })
    }, []);// 빈 배열을 전달하여 컴포넌트가 마운트될 때 한 번만 실행


    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('mbti_eeg_id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        console.log('sort by : ' + property + ' => to ' + isAsc)
        const sortList = stableSort(mbti_eeg_srcs, (a, b) => {
            if (property == 'exam_client_name' || property == 'exam_date_str' || property == 'analysis_date') {
                if (isAsc) {
                    return a[property].localeCompare(b[property]);
                } else {
                    return b[property].localeCompare(a[property]);
                }
            } else {
                if (isAsc) {
                    return a[property] - b[property];
                } else {
                    return b[property] - a[property];
                }
            }
        });
        setMbtiEegSrcs(sortList);
    };

    const handleShowMbtiEegRet = (n) => {
        console.log('----' + n + '------')
        // mbti_eeg_save_filename = mbti_eeg_src ''
        // console.log(mbti_eeg_src['exam_client_name'])
        console.log('--------------------start')
        for (const mbti_eeg_src of mbti_eeg_srcs) {
            // console.log(mbti_eeg_src.mbti_eeg_id + ':' + mbti_eeg_src.exam_client_name)
            if (mbti_eeg_src.mbti_eeg_id == n) {
                console.log(mbti_eeg_src.exam_client_name)
                setExamClientName(mbti_eeg_src.exam_client_name);
                setMbtiEegSaveFilename('MBTI결과-뇌파분포 비교_' + mbti_eeg_src.exam_client_name + '_' + mbti_eeg_src.exam_date_str + '.pdf')
                break
            }
        }
        console.log('--------------------end')
        setShowEEGResultId(n)
        setShowEEGResultOpen(true)
    };

    // const visibleRows = React.useMemo(
    //     () =>
    //       stableSort(mbti_eeg_srcs, getComparator(order, orderBy)),
    //     [order, orderBy],
    // );

    const history_table = (
        <Paper elevation={2}>
            {/* <TableContainer sx={{ maxHeight: 440 , border: 1, borderColor: 'grey.300', borderRadius: 3}}> */}
            {/* <HistoryTableToolbar/> */}
            <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                    {/* <TableHead>
                        <TableRow>
                            {history_table_columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    sx={{ backgroundColor: 'grey.300' }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead> */}
                    <HistoryTableHeader
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        // rowCount={mbti_eeg_srcs.length}
                        rowCount={2}
                    />
                    <TableBody>
                        {Array.isArray(mbti_eeg_srcs) ?
                            mbti_eeg_srcs.map((row) => {
                                return (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                        {history_table_columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell onClick={() => handleShowMbtiEegRet(row.mbti_eeg_id)}
                                                    key={column.id} align={column.align}
                                                    style={{ maxWidth: 100 }}>
                                                    {column.format && typeof value === 'number'
                                                        ? column.format(value)
                                                        : value}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>
                                );
                            }) : null
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );


    const [show_eeg_result_open, setShowEEGResultOpen] = React.useState(false);
    const [show_eeg_result_message, setShowEEGResultMessage] = React.useState(false);
    const [show_eeg_result_id, setShowEEGResultId] = React.useState(0);

    const handleShowEEGResultPageOpen = () => {
        setShowEEGResultOpen(true)
    };

    const handleShowEEGResultPageClose = () => {
        setShowEEGResultOpen(false);
    };


    const handleMbtiEegRetPrint = () => {
        //show_eeg_result_id
        console.log(mbti_eeg_save_filename)
        // generatePDF(targetRef, { filename: 'test.pdf' })
        generatePDF(targetRef, { filename: mbti_eeg_save_filename })
    };

    const eeg_src_id = show_eeg_result_id;
    const handleMbtiEegRetRemove = () => {
        const userConfirmation = window.confirm('정말 삭제하시 겠습니까 ?');
        if (userConfirmation) {
            try {
                fetch('/api/eeg_src_remove', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ eeg_src_id })
                })
                    .then((response) => {
                        // 응답 데이터를 JSON으로 파싱
                        return response.json();
                    })
                    .then((jsonData) => {
                        // 성공적으로 데이터를 가져온 경우 상태 업데이트
                        alert("삭제 성공")
                        handleShowEEGResultPageClose()
                        window.location.reload();
                    })
            } catch (error) {
                alert("삭제 실패")
                handleShowEEGResultPageClose()
            }
        }
    };


    const targetRef = useRef();

    const showEEGResultDialog = (
        <Dialog
            open={show_eeg_result_open}
            onClose={handleShowEEGResultPageClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    width: "100%",
                    maxWidth: "850px",  // Set your width here
                },
            }}
        >
            <DialogTitle id="alert-dialog-title">
                {/* {"패스워드 변경"} */}
            </DialogTitle>
            <DialogContent>
                {/* <DialogContentText id="alert-dialog-description">
                            
                        </DialogContentText> */}
                {/* <MbtiEegResultPage id='1'/> */}
                <div ref={targetRef}>
                    <MbtiEegResultPage mbti_eeg_src_id={show_eeg_result_id} user_id={userid} exam_client_name={exam_client_name} />
                </div>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' size='small'
                    onClick={handleMbtiEegRetPrint}>
                    PDF 다운로드
                </Button>
                {/* <Button variant='contained' size='small'
                    onClick={handleMbtiEegRetRemove}>
                    삭제
                </Button> */}
                <Button variant='contained' size='small'
                    onClick={handleShowEEGResultPageClose} autoFocus>
                    닫기
                </Button>
            </DialogActions>
        </Dialog>
    );



    return (
        <div>
            <Box
                sx={{
                    display: 'grid',
                    width: '100%'
                }}>
                <Box ml={3} mr={3} >
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={5}
                    >
                        <Box sx={{
                            mt: 0, ml: 0, height: 50
                        }}>
                            <h2>MBTI 뇌파 데이터 처리 히스토리</h2>
                        </Box>
                        <Stack>
                            <Paper elevation={3}>
                                <Box m={2}>
                                    <h3>데이터 처리 내역</h3>
                                    {history_table}
                                </Box>
                            </Paper>
                            <Box>

                            </Box>
                        </Stack>
                    </Stack>
                </Box>
            </Box>
            {showEEGResultDialog}
        </div>
    );
}

export default HistoryPage;