import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

function Bottom() {
    return (
        <div>
            <Divider flexItem/>
            <Box mt={2} mb={2} ml={5} mr={5}>

                <h6>두뇌 발전소</h6>
                <h6>Copyright © 2023 BrainFactory Inc. All rights reserved.</h6>
            </Box>
        </div>
    );
}


export default Bottom