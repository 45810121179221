import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
	const navigate = useNavigate();

	useEffect(() => {
		sessionStorage.removeItem('logged_in');

		navigate('/');
	}, [navigate]);

	return <div>Logging out ...</div>;
}

export default Logout;