import React from 'react';
// import { Link } from 'react-router-dom';


// import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
// import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';



import primary from '@mui/material/colors';



// import SendIcon from '@mui/icons-material/Send';
// import InboxIcon from '@mui/icons-material/MoveToInbox';
// import DraftsIcon from '@mui/icons-material/Drafts';

// import { grey, blue } from '@mui/material/colors';

import Header from './Header';
import Bottom from './Bottom';
import MyPage from './pages/MyPage';
import MbtiEegPage from './pages/MbtiEegPage';
import HistoryPage from './pages/HistoryPage';
import AdminUserPage from './pages/AdminUserPage';
import AdminQuestionPage from './pages/AdminQuestionPage';
import AdminMbtiEegPage from './pages/AdminMbtiEegPage';
import AdminMbtiEegTestPage from './pages/AdminMbtiEegTestPage';


function Home() {
	const userid = sessionStorage.getItem('userid');
	// const [message, setMessage] = useState('');


	const [body, setBody] = React.useState(<MyPage />);

	const handleMyPageClick = () => {
		setBody(
			<MyPage />
		);
	};

	const handleMbtiEegClick = () => {
		setBody(
			<MbtiEegPage />
		)
	};

	const handleHistoryClick = () => {
		setBody(
			<HistoryPage />
		)
	};

	const handleGoToAdminUserPage = () => {
		setBody(
			<AdminUserPage/>	
		)
    };

	const handleGoToAdminQuestionPage = () => {
		setBody(
			<AdminQuestionPage/>	
		)
    };

	const handleGoToAdminMbtiEegPage = () => {
		setBody(
			<AdminMbtiEegPage/>	
		)
    };


	const handleGoToAdminMbtiEegTestPage = () => {
		setBody(
			<AdminMbtiEegTestPage/>	
		)
    };

           
    const renderMenu = () => {
        if (userid == 'administrator') { 
            return (
				<List
					component="nav"
					aria-labelledby="nested-list-subheader">
					<ListItemButton onClick={handleMyPageClick}>
						<ListItemText primary="마이 페이지" />
					</ListItemButton> 
					<ListItemButton onClick={handleGoToAdminUserPage}>
						<ListItemText primary="유저 관리" sx={{ fontWeight: 'bold' }} />
					</ListItemButton>
					<ListItemButton onClick={handleGoToAdminQuestionPage}>
						<ListItemText primary="질의 관리" sx={{ fontWeight: 'bold' }} />
					</ListItemButton>
					<ListItemButton onClick={handleGoToAdminMbtiEegPage}>
						<ListItemText primary="MBTI-EEG 데이터 관리" sx={{ fontWeight: 'bold' }} />
					</ListItemButton>
					<ListItemButton onClick={handleGoToAdminMbtiEegTestPage}>
						<ListItemText primary="MBTI-EEG 그래프 테스트" sx={{ fontWeight: 'bold' }} />
					</ListItemButton>
				</List>
            )
        } else {
			return (
				<List
					component="nav"
					aria-labelledby="nested-list-subheader">
					<ListItemButton onClick={handleMyPageClick}>
						{/* <ListItemIcon>
							<SendIcon />
						</ListItemIcon> */}
						<ListItemText primary="마이 페이지" />
					</ListItemButton> 
					<ListItemButton onClick={handleMbtiEegClick} >
						<ListItemText primary="MBTI-EEG 데이터 처리" />
					</ListItemButton>
					<ListItemButton onClick={handleHistoryClick}>
						<ListItemText primary="히스토리" sx={{ fontWeight: 'bold' }} />
					</ListItemButton>
					<Divider />
				</List>
			)
		}
    };
    

	return (
		<div>
			<Grid container >
				<Grid item xs={12}>
					<Header></Header>
				</Grid>
				<Grid item xs={2}
					sx={{
						minWidth: 200
					}}>
					{renderMenu()}
				</Grid>
				<Divider orientation="vertical" flexItem />
				<Grid item xs={10} sm mb={3}>
					<Box>
						{body}
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Bottom></Bottom>
				</Grid>
			</Grid>



		</div >
	);
}


export default Home;