import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

import './index';
import './Login.css';

// import { Link } from 'react-router-dom';
import Home from './Home';

function Login() {
	const [userid, setUserid] = useState('');
	const [username, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const [message, setMessage] = useState('');

	const navigate = useNavigate();
	const isLoggedIn = sessionStorage.getItem('logged_in') === 'true';

	const handleLogin = async () => {
		console.log({ userid })
		console.log({ password })
		const response = await fetch('/api/login', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ userid, password }),
		});

		const data = await response.json();
		setMessage(data.message);
		console.log(data);
		navigate('/home');

		if (data.message === 'Login successful') {
			sessionStorage.setItem('logged_in', 'true');  // 세션에 로그인 상태 저장
			sessionStorage.setItem('userid', userid);

			if (userid == 'administrator') {
				sessionStorage.setItem('is_admin', 'true');
			} else {
				sessionStorage.setItem('is_admin', 'false');
			}
			console.log("navigate /")
			navigate('/home');
		}
	}

	if (isLoggedIn) {
		navigate('/home')
	}

	const handleSignUp = async () => {
		navigate('/signup')
	}

	return (
		<div >
			{isLoggedIn ? (
				<Home />
			) : (
				<Container component="main" maxWidth='xs'>
					<Box
						sx={{
							marginTop: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						<Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component="h1" variant="h5" mb={3}>
							로그인
						</Typography>
						<Paper
							elevation={20}>
							<Box sx={{ m: 5 }}>
								<TextField
									label="user id"
									margin="normal"
									required
									fullWidth
									id="userid"
									name="userid"
									autoFocus
									onChange={(e) => setUserid(e.target.value)}
								/>
								<TextField
									label="Password"
									type="password"
									margin="normal"
									required
									fullWidth
									name="password"
									id="password"
									autoComplete="current-password"
									onChange={(e) => setPassword(e.target.value)}
								/>
								<p>{message}</p>
							</Box>
						</Paper>
						{/* <Checkbox value="remember" color="primary"></Checkbox> */}
						<FormControlLabel
							control={<Checkbox value="remember" color="primary" />}
							label="Remember me" />
						{/* <Button variant='contained' fullWidth type="submit" sx={{ mt:3, mb:2}}>Sign in</Button> */}
						<Button
							variant='contained' fullWidth type="submit"
							onClick={handleLogin}
							sx={{ mt: 3, mb: 2 }}>
							로그인
						</Button>
						<Stack >
							<Button>비밀 번호 찾기</Button>

							<Button onClick={handleSignUp}>회원가입</Button>
						</Stack>
					</Box>
				</Container>
			)}
		</div>
	)
}

export default Login;