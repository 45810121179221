import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import brainfactoryLogo from './images/brainfactory_logo01_mini.png'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Fab from '@mui/material/Fab';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import primary from '@mui/material/colors';
import info from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

import red from '@mui/material/colors';


function Header() {
	const userid = sessionStorage.getItem('userid');
	const is_admin = sessionStorage.getItem('is_admin');
	const navigate = useNavigate();

	const [logout_open, setLogoutOpen] = React.useState(false);
	const handleLogoutOpen = () => {
		setLogoutOpen(true);
	};

	const handleLogoutClose = () => {
		setLogoutOpen(false);
	};

	const handleLogout = async () => {
		setLogoutOpen(false);
		const response = await fetch('/api/logout', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ userid }),
		});

		const data = await response.json();
		// setMessage(data.message);
		console.log(data);

		sessionStorage.removeItem('is_admin');  // 세션에 로그인 상태 저장
		sessionStorage.removeItem('logged_in');  // 세션에 로그인 상태 저장
		sessionStorage.removeItem('userid');

		window.location.reload();
		navigate('/');
	}

	// const go_manage_page = (
	// 	if (is_admin == 'true') {
	// }
	// );


	return (
		// <h1>여기는 헤더 입니다.</h1>
		<div>
			<Box sx={{ height: 60, boxShadow: 3, backgroundColor: 'primary.main' }}>
				<Grid container >
					<Grid item xs={6}>

					</Grid>
					<Grid item xs={6} >
						<Stack
							m={1.5}
							// sx={{ height: 60}}
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							spacing={2}>
							{/* <Fab variant="extended" onClick={handleLogout} size='small'>
								Logout
							</Fab> */}
							<Button
								variant="contained"
								color='info'
								onClick={handleLogoutOpen}
								// onClick={handleLogout} 
								size='small'>
								Logout
							</Button>

						</Stack>
					</Grid>
				</Grid>
			</Box>
			{/* <Stack direction="row" alignItems="stretch">
				<Box sx={{ width: 250 }}>
					<img className="photo"
						src={brainfactoryLogo}
						alt="brain factory Inc. logo"
						width={130}
						height={40}
					/>
				</Box>
				<Box sx={{ height: 60, boxShadow: 3, backgroundColor: 'primary' }}>
					<h1>aa</h1>
				</Box>
			</Stack> */}
			<Dialog
				open={logout_open}
				onClose={handleLogoutClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"로그아웃 하시겠습니까?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">

					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant='contained' onClick={handleLogout} size='small'>
						예
					</Button>
					<Button variant='contained' onClick={handleLogoutClose} size='small' autoFocus>
						아니요
					</Button>
				</DialogActions>
			</Dialog>
		</div >
	)
}




export default Header